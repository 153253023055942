<template>
  <!-- end header -->
        <br style="margin-top: 40px;">
        <!-- subscriptions -->
        <section class="section">
            <div class="container">
                <div class="row">
                </div>
            </div>
        </section>
        <!-- end subscriptions -->

        <!-- catalog -->
        <div class="catalog2 catalog--page">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ul class="title"><li style="margin-left: 30px;margin-right: 10px;"><h6 > التصنيف </h6></li><li style="margin-left: 30px;">-</li><li style="margin-left: 30px;"><h6 >كوميديا </h6></li></ul>
                    </div>
                    <div class="col-12">
                        <div class="row row--grid">
                            <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in comedy" :key="publish.id">
                                <div class="card">
                                    <a @click="getFeed(publish)" class="card__cover">
                                        <img :src="publish.img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title"><a @click="getFeed(publish)">{{ publish.title_ar }}</a></h3>
                                    <ul class="card__list">
                                        <li></li>
                                        <li>{{ publish.name_ar }}</li>
                                        <li>{{ publish.year }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>		
                <!-- <div class="col-12">
                    <div class="catalog__paginator-wrap">
                        <span class="catalog__pages">12 from 144</span>

                        <ul class="catalog__paginator">
                            <li>
                                <a href="#">
                                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1992 5.3645L0.75 5.3645" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.17822 0.602051L13.1993 5.36417L8.17822 10.1271" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </a>
                            </li>
                            <li class="active"><a href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li>
                                <a href="#">
                                   <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.75 5.36475L13.1992 5.36475" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.771 10.1271L0.749878 5.36496L5.771 0.602051" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg> 
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>

        </div>
        <!-- end catalog -->
</template>

<script>
import axios from "axios";
import config from '@/Api/config';

export default {
   name: 'Comedys',
   async setup() {
      const comedy = await axios.get('GetContentByCategory.php?cat_id=5&LIMIT=18&OFFSET=0',{
           headers: {
               'Authorization': config.authHeader,
            },
      }).then((response) => {return response.data.Content;});
      return { comedy };
  },
  methods: {
    getFeed: function (publish) {
      this.$cookie.setCookie('Content_id', publish.id);
      this.$cookie.setCookie('cat_id', publish.cat_id);
      if (this.$cookie.isCookieAvailable("minutes") && this.$cookie.isCookieAvailable("mdn")) {
        axios.get(
            "CheckUserCategory.php?msisdn="+this.$cookie.getCookie("mdn")+"&product_code="+publish.cat_id
            ,{
           headers: {
               'Authorization': config.authHeader,
            },
      }).then((response) => {
              if (response.data.MINTUES > 0) {
                this.$router.push("/Content");
              } else {
                this.$router.push("/Trailer");
              }
          });
         
      } else {
        this.$router.push("/Trailer");
      }
    },
  },
}
</script>

<style scoped>

.title {
    font-family: 'Cairo', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: 0.5s;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    word-wrap: break-word;
    background-color: #047072;
    background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
    padding: 8px 5px 5px 5px;
    border-radius: 12px;


}
.catalog2 {
    position: relative;
    padding: 5px 0 25px;
    padding-top: calc(47vh - 100%);
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    overflow: hidden;
    padding-bottom: calc(50vh - 60%);
}
.catalog__paginator-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto 0;
    width: 100%;
    height: 50px;
    border-radius: 16px;
    background-color: #151f30;
    background-color: #047072;
    background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
}
/*.catalog__paginator li {
    margin-left: 10%;
}
@media (max-width:360px){
.catalog2 {
    padding-top: 10%;
}
}
@media (max-width:320px){
.catalog2 {
    padding-top: 10%;
}
}
@media (max-width:768px){
.catalog2 {
    padding-top: 10%;
}
} */
</style>